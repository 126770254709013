exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-aboutme-jsx": () => import("./../../../src/pages/aboutme.jsx" /* webpackChunkName: "component---src-pages-aboutme-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy_policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-recommended-posts-jsx": () => import("./../../../src/pages/recommended-posts.jsx" /* webpackChunkName: "component---src-pages-recommended-posts-jsx" */),
  "component---src-pages-recommended-posts-stock-analysis-for-investors-jsx": () => import("./../../../src/pages/recommended-posts/stock-analysis-for-investors.jsx" /* webpackChunkName: "component---src-pages-recommended-posts-stock-analysis-for-investors-jsx" */),
  "component---src-templates-index-jsx": () => import("./../../../src/templates/index.jsx" /* webpackChunkName: "component---src-templates-index-jsx" */),
  "component---src-templates-page-jsx": () => import("./../../../src/templates/page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */),
  "component---src-templates-post-jsx": () => import("./../../../src/templates/post.jsx" /* webpackChunkName: "component---src-templates-post-jsx" */)
}

